.filters {
    display: flex;
    width: 100%;
    overflow-y: auto;
    padding-top: 5px;

    &__tab {
        height: 20px;
        font-family: 'Cerebri Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        color: #353535;
        margin-bottom: 8px;
        margin-right: 32px;
        cursor: pointer;
        white-space: nowrap;
    }

    &__bottom {
        height: 2px;
        background: #28DAB6;
        width: 100%;
    }

    &__amount {
        margin-left: 20px;
        align-items: center;
        padding: 2.5px 8px 1.5px;
        width: 32px;
        height: 20px;
        background: #353535;
        border-radius: 10px;
        color: white;
    }
}