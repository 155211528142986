.table {
    width: 100%;
    overflow: auto;

    table {
        max-width: 1200px;
        width: 100%;
        border-collapse: collapse;
    }

    tr {
        border-bottom: 1px solid lightgray;
    }

    th {
        text-align: left;
        white-space: nowrap;
        padding-bottom: 24px;
    }

    &__title {
        height: 16px;
        min-width: 100px;
        font-family: 'Cerebri Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        text-transform: uppercase;
        color: #353535;
        opacity: 0.32;
    }

    &__body-td {
        height: 20px;
        font-family: 'Cerebri Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #000000;
    }

    &__body-transaction-id {
        padding: 12px 0px;
        display: flex;
        flex-direction: column;
    }

    &__transaction-id {
        height: 20px;
        font-family: 'Cerebri Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        color: #28DAB6;
    }

    &__issuer-name {
        font-family: Cerebri Sans;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #353535;
        opacity: 0.56;
    }

    &__buttons {
        display: flex;
        justify-content: center;
        margin-top: 30px;
    }
}