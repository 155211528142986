.side-nav {
    width: 264px;
    height: 180px;
    background: #3535350f;
    padding: 40px 0px 40px 32px;
    margin-bottom: 48px;

    &__tab {
        font-family: Cerebri Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        height: 20px;
        color: #353535;
        margin-bottom: 20px;
        cursor: pointer;

        &:last-child {
            margin-bottom: 0px;
        }
    }

    &__clicked {
        color: #b5b5b5;
    }

    &__container {
        display: flex
    }
}