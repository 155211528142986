.note {
    padding: 32px 0px;
    display: flex;

    &__img {
        width: 40px;
        height: 40px;
        margin-right: 28px;
        cursor: pointer;

        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }
    }

    &__green-label {
        font-family: 'Cerebri Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        color: #28DAB6;
    }

    &__time {
        font-family: Cerebri Sans;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #353535;
        opacity: 0.56;
    }

    &__comment {
        font-family: 'Cerebri Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #353535;
    }
}