.reports {
    margin-bottom: 48px;

    &__btn-approve {
        width: 100px;
        height: 32px;
        border: none;
        border-radius: 2px;
        margin-right: 10px;
        cursor: pointer;
        margin-top: 24px;
    }

    &__csv-btn {
        width: 150px;
        height: 32px;
        border: none;
        border-radius: 2px;
        cursor: pointer;
        margin: 0px 0px 24px 0px;
    }
}