.medallions {
    &__header {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        max-width: 1200px;
        width: 100%;
        margin-bottom: 48px;

        &--title {
            max-width: 380px;
            width: 100%;
            height: 32px;
            font-family: 'Cerebri Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 22px;
            line-height: 32px;
            color: #0C234C;
        }

        &--buttons {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }

    &__btn-approve {
        width: auto;
        height: 32px;
        border: none;
        border-radius: 2px;
        background: #28DAB6;
        color: #fff;
        margin-right: 10px;
        cursor: pointer;
    }

    &__btn-decline {
        width: auto;
        height: 32px;
        border: none;
        border-radius: 2px;
        background: #FA4646;
        color: #fff;
        margin-right: 10px;
        cursor: pointer;
    }

    &__btn-disabled {
        width: auto;
        height: 32px;
        border: 2px solid #d4d4d4;
        border-radius: 2px;
        color: #000;
        background: #d4d4d4;
        cursor: not-allowed;
    }

    &__btn-include {
        width: 100px;
        height: 32px;
        border: 2px solid #28DAB6;
        border-radius: 2px;
        color: #28DAB6;
        background: #fff;
        cursor: pointer;
    }

    &__table-td {
        padding: 12px 0px;
        height: 32px;
    }

    &__td-body {
        width: 112px;
        height: 20px;
        font-family: 'Cerebri Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #353535;
    }

    &__generate-box {
        margin-top: 48px;
        box-sizing: border-box;
        max-width: 1200px;
        width: 100%;
        height: auto;
        padding: 44px;
        background: rgba(53, 53, 53, 0.06);
        border: 2px solid rgba(53, 53, 53, 0.16);
        display: flex;
        flex-direction: column;

        &--title {
            flex: 1;
            width: 112px;
            height: 16px;
            font-family: 'Cerebri Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            line-height: 16px;
            text-transform: uppercase;
            color: #353535;
            opacity: 0.56;
        }

        &--select {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
        }

        &--return-title {
            height: 20px;
            left: 988px;
            font-family: 'Cerebri Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            text-align: right;
            color: #353535;
        }

        &--return-price {
            height: 20px;
            left: 988px;
            font-family: 'Cerebri Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            text-align: right;
            color: #353535;
            opacity: 0.8;
        }

        &--btn {
            width: 268px;
            height: 48px;
            border: none;
            background: #28DAB6;
            border-radius: 2px;
            cursor: pointer;
            color: #fff;
        }
    }

    &__certificates {
        &-headers {
            display: flex;
            font-family: "Cerebri Sans";
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            color: #353535;
            margin-bottom: 8px;
            white-space: nowrap;
            padding: 5px 15px;

            &-body {
                display: flex;
                height: 50px;
                margin-bottom: 15px;
                align-items: center;
                padding: 5px 15px;
                border: 3px solid #28DAB6;
                border-radius: 15px;
                cursor: pointer;
            }
        }

    }
}