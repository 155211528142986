.notes {
    &__textarea {
        margin-top: 48px;
        width: 100%;
        min-height: 80px;
        height: 88px;
        background: #FFFFFF;
        border-radius: 2px;
        border: 1px solid rgba(53, 53, 53, 0.3);
        resize: none;
        padding: 16px;
        font-family: 'Cerebri Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #353535;
        opacity: 0.56;
    }

    &__button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 14px 32px;
        width: 133px;
        height: 48px;
        background: #28DAB6;
        border-radius: 2px;
        border: none;
        margin-top: 24px;
        color: white;
        cursor: pointer;
    }

    &__loader {
        padding: 32px 0px;
    }
}