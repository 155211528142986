.navigator {
    display: flex;
    align-items: center;

    &__name {
        height: 18px;
        font-family: 'Cerebri Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #353535;
        opacity: 0.8;
        margin-right: 8px;
    }

    &__arrow {
        width: 20px;
        height: 20px;
    }

    &__hr {
        max-width: 1200px;
        width: 100%;
        opacity: 0.2;
        margin-bottom: 48px;
    }
}