.transaction-verifications {
    &__table-td {
        padding: 12px 0px;
    }

    &__td-body {
        width: 112px;
        height: 20px;
        font-family: 'Cerebri Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #353535;
    }
}