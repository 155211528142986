.user {
    margin-bottom: 48px;

    &__hr {
        margin: 48px 0px;
        max-width: 1200px;
        width: 100%;
    }

    &__body-td {
        padding: 12px 0px;
        display: flex;
        flex-direction: column;
    }

    &__loader {
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (max-width: 992px) {
    .user {
        &__loader {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}