.reset-idology {
    &__btn {
        width: 100px;
        height: 32px;
        border: none;
        border-radius: 2px;
        background: green;
        color: #fff;
        margin-right: 10px;
        cursor: pointer;
    }
}