/* @font-face {
  font-family: "CerebriSans-BoldItalic";
  src: url("fonts/353ECC_0_0.eot");
  src: url("fonts/353ECC_0_0.eot?#iefix") format("embedded-opentype"),
    url("fonts/353ECC_0_0.woff2") format("woff2"),
    url("fonts/353ECC_0_0.woff") format("woff"),
    url("fonts/353ECC_0_0.ttf") format("truetype");
} */

@font-face {
  font-family: 'Cerebri Sans';
  src: url('fonts/Cerebri.Sans.Pro/CerebriSansPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pointer {
  cursor: pointer;
}

.right-panel-body {
  width: 65%;
}

.hr {
  width: 100%;
  opacity: 0.2;
}

.input {
  width: 100%;
  background: #FFFFFF;
  border-radius: 2px;
  height: 48px;
  border: 1px solid lightgray;
  font-family: 'Cerebri Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #353535;
  opacity: 0.8;
  padding: 0 16px;
  box-sizing: border-box;
}

.error {
  border: 1px solid red;
}

.error-color {
  color: red;
}

.uppercase {
  text-transform: uppercase
}

.d-block {
  display: block
}

.pointer {
  cursor: pointer;
}

.or-line {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #e9e9e9;
  line-height: 0.1em;
  margin: 10px 0 20px;
  color: #e9e9e9;
  font-size: 14px;
}

@media screen and (max-width: 992px) {
  .right-panel-body {
    width: 100%;
  }
}