.login {
  width: 100%;

  &__form {
    height: auto;
    padding: 50px;
    max-width: 500px;
    box-shadow: 0px 2px 25px -9px #333;
    border-radius: 5px;
    background: #fff;
    width: 500px;
    padding: 30px;

    &-title {
      display: block;
      text-align: center;
      width: 100%;
      color: #353535;
      font-family: 'Cerebri Sans';
      font-size: 24px;
      margin-bottom: 20px;
    }

    &-body {
      display: flex;
      flex-direction: column;

      &-line {
        height: 100px;
      }
    }


    input {
      font-family: 'Cerebri Sans';
    }

    .btn-login {
      background: #28dab6;
      color: #fff;
      border-radius: 2px;
      transition: 300ms ease-in-out;
      padding: 0 25px;
      margin-top: 15px;
      width: 100%;
      font-family: 'Cerebri Sans';
      font-size: 17px;
      height: 45px;
      border: none;
      cursor: pointer;

      &:hover {
        background: #01b5a2;
      }
    }

  }


  &__google-login {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}