.create-discount {
    margin-bottom: 48px;

    &__hr {
        max-width: 1200px;
        width: 100%;
        opacity: 0.2;
        margin-bottom: 48px;
    }


    &__form {
        display: flex;
        flex-direction: column;
        max-width: 1200px;
        flex-wrap: wrap;
        gap: 15px;
        align-content: center;
        margin: 48px auto;

        & select {
            width: 250px;
            height: 250px;
        }
    }

    &__button {
        display: flex;
        align-self: center;
        align-items: center;
        justify-content: center;
        max-width: 700px;
        width: 100%;
        height: 48px;
        border: none;
        background: #28DAB6;
        border-radius: 2px;
        cursor: pointer;
        color: #fff;
        margin-top: 32px;
    }
}