.payment-refund {
    width: 100%;
    max-width: 750px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &__label {
        height: 16px;
        font-family: 'Cerebri Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        text-transform: uppercase;
        color: #353535;
        opacity: 0.32;
    }

    &__sub-label {
        height: 20px;
        font-family: 'Cerebri Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #353535;
    }

    &__refund-box {
        margin-top: 48px;
        box-sizing: border-box;
        width: 100%;
        max-width: 750px;
        height: auto;
        background: rgba(53, 53, 53, 0.06);
        border: 2px solid rgba(53, 53, 53, 0.16);
        padding: 40px;
    }

    &__title {
        height: 16px;
        font-family: 'Cerebri Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        text-transform: uppercase;
        color: #353535;
        opacity: 0.56;
    }

    &__btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 14px 32px;
        width: 133px;
        height: 48px;
        background: #28DAB6;
        border-radius: 2px;
        border: none;
        color: white;
        cursor: pointer;
    }
}