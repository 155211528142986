.all-discounts {
    td {
        padding: 12px 5px;
    }

    &__header {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &__btn-approve {
        width: 200px;
        height: 48px;
        border: none;
        border-radius: 2px;
        background: #28DAB6;
        color: #fff;
        margin-right: 10px;
        cursor: pointer;
    }

    &__sub-date {
        font-family: Cerebri Sans;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #353535;
        opacity: 0.56;
        display: block;
    }

    &__code {
        height: 20px;
        font-family: 'Cerebri Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        color: #28DAB6;
    }
}