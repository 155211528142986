.navbar {

    width: 100%;
    height: 48px;
    background: #3535350f;
    padding-top: 34px;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__logo {
        max-width: 328px;
        max-height: 48px;
        margin-left: 56px;
        cursor: pointer;
    }

    &__img {
        width: 40px;
        height: 40px;
        margin-right: 56px;
        cursor: pointer;

        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }
    }
}

@media screen and (max-width: 992px) {
    .navbar {
        &__logo {
            max-width: 164px;
            max-height: 24px;
            margin-left: 28px
        }

        &__img {
            width: 20px;
            height: 20px;
            margin-right: 28px;
        }
    }
}