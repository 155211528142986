.fedex-labels {
    &__title {
        font-family: Cerebri Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 36px;
        width: 100%;
        margin-bottom: 24px;
    }

    &__green-label {
        font-family: 'Cerebri Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        color: #28DAB6;
        cursor: pointer;
    }
}