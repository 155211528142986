.transaction-details {
    display: flex;
    flex-wrap: wrap;
    max-width: 792px;
    width: 100%;

    &__col {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    &__title {
        font-family: 'Cerebri Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        text-transform: uppercase;
        color: #353535;
        opacity: 0.32;
    }

    &__id {
        font-family: 'Cerebri Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 36px;
        color: #0C234C;
    }

    &__green-label {
        font-family: 'Cerebri Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        color: #28DAB6;
    }

    &__separator {
        margin-top: 24px;
    }

    &__regular-title {
        font-family: 'Cerebri Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #353535;
    }
}