.delete-user {
    &__btn {
        &-delete {
            width: 100px;
            height: 32px;
            border: none;
            border-radius: 2px;
            color: #fff;
            margin-right: 10px;
            cursor: pointer;
            background: red;
        }

        &-unlock {
            width: 100px;
            height: 32px;
            border: none;
            border-radius: 2px;
            color: #fff;
            margin-right: 10px;
            cursor: pointer;
            background: green;
        }
    }
}