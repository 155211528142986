.search-box {
    &__container {
        position: relative;
        max-width: 380px;
    }

    background: #FFFFFF;
    border-radius: 2px;
    width: 100%;
    height: 48px;
    padding: 0 16px;
    font-family: 'Cerebri Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    border: 1px solid lightgray;
    color: #353535;
    opacity: 0.8;
    text-transform: uppercase;

    &__search-icon {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 14px;
        right: -20px;
    }
}