.page-title {
    font-family: Cerebri Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    width: 100%;

    &__desc {
        font-family: Cerebri Sans;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
    }

    &__hr {
        width: 100%;
        opacity: 0.2
    }
}